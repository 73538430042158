<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="otherGroups && otherGroups.length > 0">
      <!-- TABLE HEADING -->
      <div class="row m-0">
        <!--MOBILE-->
        <div class="col-12 d-md-none">
          <div class="row font13 green-text-dark">
            <div class="col-6 bold text-center px-1">
              Group
            </div>
            <div class="col bold text-center px-1">
              Date
            </div>
            <div class="col bold text-center px-1">
              Info
            </div>
          </div>
        </div>
        <!--END MOBILE-->
        <!--DESKTOP-->
        <div class="col-12 d-none d-md-block">
          <div class="row font13 green-text-dark">
            <div class="col-md-3 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-left">
                GROUP NAME
              </div>
            </div>
            <div class="col-md-3 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3">
                GROUP ADMIN
              </div>
            </div>
            <div class="col-md-3 bold text-md-center px-1">
              <div class="lightgrey_bg w-100 h-100 p-3">
                JOINED
              </div>
            </div>
            <div class="col-md-3 bold text-md-center px-0">
              <div class="lightgrey_bg w-100 h-100 p-3 rounded-right">
                INFO
              </div>
            </div>
          </div>
        </div>
         <!--END DESKTOP-->
      </div>
      <!-- END TABLE HEADING -->
      <GroupsMemberItem v-for="(donation, key) in otherGroups.slice(start, finish)" :key="key" :item="donation" :thekey="key" @showDetails="viewDonations(key)" @exitGroup="exitGroup(key)" />
      <transition name="slideup">
      <DonationPopup v-if="isViewGroupDonations" :item="currentItem" :theKey="currentKey" :totalItems="otherGroups.length" @close="closeDetails" @goForward="goForward(currentKey)" @goBack="goBack(currentKey)" />
      </transition>
      <!-- PAGINATION -->
      <div class="row mx-0 mt-4" v-if="totalPages > 1">
        <div class="col-12 text-center">
          <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: currentPage === index - 1}">
            {{ index }}
          </div>
        </div>
      </div>
      <!-- END PAGINATION -->
    </div>
    <div class="col-12" v-else>
      <div class="row mx-0  my-3 justify-content-center align-items-center" v-if="groupsStatus !== 'ready'">
        <div class="col-auto ps-0">
          {{groupsStatus}} Groups
        </div>
        <div class="col-auto p-0">
          <div class="spinner-border spinner-border-sm green-text" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="m-3 text-center" v-else>
        You are not a member of any group, try adding a group.
      </div>
    </div>
    <transition name="fade">
      <Popup @close="closePopup" v-if="isExitGroup">
        <div class="row mx-0 justify-content-center">
          <div class="col-12 text-center mb-3 green-text bold font19">
            Leave Group
          </div>
          <div class="col-12 text-center mb-3">
            Are you sure you would like to leave {{currentItem.donorGroupName}}
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="grey" btnText="Stay in Group" width="100" @buttonClicked="closePopup" />
          </div>
          <div class="col-6 px-1 text-center">
            <Button color="red" btnText="Leaving Group..." width="100" disabled v-if="groupMembersStatus === 'removing'" />
            <Button color="red" btnText="Leave Group" width="100" @buttonClicked="reallyExitGroup" v-else />
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    DonationPopup: defineAsyncComponent(() => import('./DonationPopup.vue')),
    GroupsMemberItem: defineAsyncComponent(() => import('./GroupsMemberItem.vue')),
    Popup: defineAsyncComponent(() => import('./Popup.vue'))
  },
  name: 'Groups Member Of',
  props: ['groupsearch'],
  data () {
    return {
      projectType: null,
      startDate: null,
      endDate: null,
      priceRange: null,
      donationType: null,
      itemsPerPage: 3,
      currentPage: 0,
      currentItem: null,
      currentKey: null,
      isExitGroup: false,
      isViewGroupDonations: false

    }
  },
  computed: {
    ...mapGetters(['user', 'groups', 'otherGroups', 'groupMembers', 'groupMembersStatus', 'groupsStatus']),
    totalPages () {
      return Math.ceil(this.otherGroups.length / this.itemsPerPage)
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    ...mapActions(['leaveGroup', 'fetchGroupMembers']),
    goToPage (val) {
      this.currentPage = val - 1
    },
    exitGroup (val) {
      this.isExitGroup = true
      this.currentItem = this.otherGroups[val]
    },
    async reallyExitGroup () {
      await this.fetchGroupMembers(this.currentItem.donorGroupID)
      const theGroupMember = this.groupMembers.find(x => x.member.userID === this.user.userID)
      const memberID = theGroupMember.donorGroupMembershipID
      const details = {
        groupID: this.currentItem.donorGroupID,
        memberID: memberID
      }
      await this.leaveGroup(details)
      this.closePopup()
    },
    viewDonations (val) {
      this.currentItem = this.otherGroups[val]
      this.currentKey = val
      this.$router.push('/profile/' + this.currentItem.donorGroupID)
    },
    goForward (val) {
      this.currentItem = this.otherGroups[val + 1]
      this.currentKey = val + 1
    },
    goBack (val) {
      this.currentItem = this.otherGroups[val - 1]
      this.currentKey = val - 1
    },
    closePopup () {
      this.currentItem = null
      this.currentKey = null
      this.isExitGroup = false
    },
    closeDetails () {
      this.currentItem = null
      this.currentKey = null
      this.isViewGroupDonations = false
    }
  }
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
@media (min-width: 992px) {
  .rounded-left {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }
  .rounded-right {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 100px;
  }
}
.slideup-enter-active,
.slideup-leave-active {
  transition: all 0.5s ease;
}

.slideup-enter-from,
.slideup-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
